import { Component } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'lp-footer',
  templateUrl: './lp-footer.component.html',
  styleUrls: ['./lp-footer.component.scss']
})
export class LpFooterComponent {

  public lp:any = null;

  constructor(
    public storage: DataService
  ){

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.lp = this.storage.getLp();
  }

}
