import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'lp-header',
  templateUrl: './lp-header.component.html',
  styleUrls: ['./lp-header.component.scss']
})
export class LpHeaderComponent implements OnInit{

  public lp:any = null;

  constructor(
    public storage: DataService,
    public router: Router
  ){

  }
  /**
   * 
   * To home
   * 
   */
  toHome(){

    this.router.navigateByUrl("/lp/"+this.lp.apelido);

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.lp = this.storage.getLp();
  }

}
